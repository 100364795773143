import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <main>
      <div className='home'>
        <h1>Home</h1>
        <h1></h1>
        <h1></h1>
        <Link name='feature' to='/feature'>
          <button className='featureB'>Feature</button>
        </Link>
        <Link name='location' to='/location'>
          <button className='locationB'>Locations</button>
        </Link>
        <Link name='category' to='/categ'>
          <button className='categoryB'>Category</button>
        </Link>
      </div>
      
      <p className='warning' style={{ color: 'red' }}>*Refreshing will log you out*</p>
      <p className='warning' style={{ color: 'red' }}>*If Create, Edit, Delete does not work, try relogin*</p>
    </main>
  );
}

export default Home;
