import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function LocationList() {
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    fetch('https://sus-map-backend.vercel.app/location')
      .then((response) => response.json())
      .then((locations) => {
        const sortedLocations = locations.sort((a, b) => a.name.localeCompare(b.name));
        setLocations(sortedLocations);
      })
      .catch((error) => console.error('Error fetching locations:', error));
  }, []);

  return (
    <main>
      <div className='list'>
        <h1>Locations</h1>
        <nav>
          <ul>
            {locations.map((location) => (
              <li key={location._id}>
                <Link to={`/location/${location._id}`}>{location.name}</Link>: {location?.address || "No Address"}
              </li>
            ))}
          </ul>
        </nav>
        <Link name='feature' to='/location/new'>
          <button name='feature'>Create New Location</button>
        </Link>
      </div>
      <p style={{ color: 'red' }}>*Refreshing will log you out*</p>
      <p style={{ color: 'red' }}>*If Create, Edit, Delete does not work, try relogin*</p>
    </main>
  );
}

export default LocationList;
