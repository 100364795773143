import React, { useState } from 'react';

function LocationNew() {
  const [location, setLocation] = useState({
    name: '',
    address: ''  
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch('https://sus-map-backend.vercel.app/location', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(location),
    }).then(() => {
      setLocation({ name: '', address:''});
    }).catch(error => {
      console.error('Error creating location:', error);
    });
  };
  const canSubmit = location.name;
  return (
    <main>
      <center className='editMode'>
      <form onSubmit={handleSubmit}>
        <label>
          Location Name :      <br></br>
          <input 
            type="text" 
            placeholder='Location Name...(Required)'
            value={location.name} 
            onChange={(e) => setLocation({ ...location, name: e.target.value })} 
          />      <br></br>
        </label>

        <br></br>

        <label>
          Address :      <br></br>
          <textarea 
            placeholder='Address...(Optional)'
            value={location.address} 
            onChange={(e) => setLocation({ ...location, address: e.target.value })} 
          />      <br></br>
        </label>

        <br></br>
        <button type="submit" disabled={!canSubmit}>Create Location</button>
        <p style={{color:'red'}}>*Need name to make a Location*</p>
      </form>
      </center>
    </main>
  );
}

export default LocationNew;
