import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function FeatureList() {
  const [features, setFeatures] = useState([]);
  const [categories, setCategories] = useState({});
  const [sortType, setSortType] = useState('byName'); // 'byName' or 'byCategory'
  const [rawFeatures, setRawFeatures] = useState([]); // Keep the unsorted features data

  useEffect(() => {
    const fetchFeatures = fetch('https://sus-map-backend.vercel.app/feature')
      .then(response => response.json())
      .catch(error => console.error('Error fetching features:', error));

    const fetchCategories = fetch('https://sus-map-backend.vercel.app/categ')
      .then(response => response.json())
      .catch(error => console.error('Error fetching categories:', error));

    Promise.all([fetchFeatures, fetchCategories]).then(([featuresData, categoriesData]) => {
      const categoriesMap = categoriesData.reduce((acc, cat) => {
        acc[cat._id] = cat.title;  // Assuming category object has _id and title
        return acc;
      }, {});

      setCategories(categoriesMap);
      setRawFeatures(featuresData.map(feature => ({
        ...feature,
        categoryName: categoriesMap[feature.category] || 'No Category'
      })));
    });
  }, []);

  // Automatically re-sort when sortType changes or raw features are updated
  useEffect(() => {
    if (rawFeatures.length > 0) {
      const sortedFeatures = [...rawFeatures];
      if (sortType === 'byName') {
        sortedFeatures.sort((a, b) => a.name.localeCompare(b.name));
      } else if (sortType === 'byCategory') {
        sortedFeatures.sort((a, b) => a.categoryName.localeCompare(b.categoryName));
      }
      setFeatures(sortedFeatures);
    }
  }, [sortType, rawFeatures]);

  const handleSortChange = (type) => {
    setSortType(type);
  };

  return (
    <main>
      <div>
        <h1>Features</h1>
        <button onClick={() => handleSortChange('byName')}>Sort by Name</button>
        <button onClick={() => handleSortChange('byCategory')}>Sort by Category</button>
        <nav className="list">
          <ul>
            {features.map((feature) => (
              <li key={feature._id}>
                {sortType === 'byName' ? (
                  <React.Fragment>
                    <Link to={`/feature/${feature._id}`}>{feature.name}</Link>: Category: {feature.categoryName}, Description: {feature.description || 'No Description'}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Link to={`/feature/${feature._id}`}>{feature.categoryName}</Link>: Feature: {feature.name}, Description: {feature.description || 'No Description'}
                  </React.Fragment>
                )}
              </li>
            ))}
          </ul>
          <Link to='/feature/new'>
            <button>Create New Feature</button>
          </Link>
        </nav>
      </div>
      <p style={{ color: 'red' }}>*Refreshing will log you out*</p>
      <p style={{ color: 'red' }}>*If Create, Edit, Delete does not work, try relogin*</p>
    </main>
  );
}

export default FeatureList;
