import React from 'react';
import errorImg from './error.jpg';


function TroubleModal({ isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <h2>Troubleshooting Login Issues</h2>
        <p>Please ensure your credentials are correct and your account is active. If you've forgotten your password., Contact Head of the department, or reset password via MongoDB.</p>
        <h2>Or if you see this error:</h2>
        <img src={errorImg} alt="Login failed1: Could not connect to MongoDB: Can't call 'openUri(' on an active connection with different connection strings. Make sure you aren't calling *mongoose.connect) ' multiple times. See: https://mongoosejs.com/docs/connections.html#multiple_connections" className='errorImg'></img>
        <p>open this link to check backend status:</p>
        <p><a href="https://sus-map-backend.vercel.app/troubleShoot">Link</a></p>

        <p style={{ color: 'red' }}>If you see server is crashed in the link above, please refresh the page.</p>
      </div>
    </div>
  );
}

export default TroubleModal;
