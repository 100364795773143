import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

function LocationDetail() {
  const { locationId } = useParams();
  const navigate = useNavigate();
  const [location, setLocation] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editedLocation, setEditedLocation] = useState({});

  useEffect(() => {
    fetch(`https://sus-map-backend.vercel.app/location/${locationId}`)
      .then((response) => response.json())
      .then((locationData) => {
        setLocation(locationData);
        setEditedLocation(locationData); // Initialize edit form with location data
      })
      .catch(error => console.error('Error fetching location:', error));
  }, [locationId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedLocation(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    fetch(`https://sus-map-backend.vercel.app/location/${locationId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(editedLocation),
    })
      .then(response => response.json())
      .then(data => {
        setLocation(data);
        setEditMode(false); // Exit edit mode
      })
      .catch(error => console.error('Error updating location:', error));
  };

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this location?')) {
      fetch(`https://sus-map-backend.vercel.app/location/${locationId}`, {
        method: 'DELETE'
      })
        .then(() => {
          // Redirect to another page after deletion, for example the home page or location list
          navigate('/location');
        })
        .catch(error => console.error('Error deleting location:', error));
    }
  };

  if (!location) return <div>Loading location details...</div>;

  return (
    <main>
      <div className='detail'>
        {editMode ? (
          <div className='editMode'>
            <label>Name: </label>
            <input type="text" name="name" value={editedLocation.name} onChange={handleInputChange} /><br /><br />
            <label>Address: </label>
            <input type="text" name="address" value={editedLocation.address} onChange={handleInputChange} /><br /><br />
            <button onClick={handleSubmit}>Submit Changes</button>
            <button onClick={() => setEditMode(false)}>Cancel</button>
          </div>
        ) : (
          <div>
            <h1>{location.name}</h1>
            <p>Address: {location.address || 'No address available.'}</p>
            
            <button onClick={handleDelete}>Delete Location</button>
            <button onClick={() => setEditMode(true)}>Edit Location</button>
          </div>
        )}
      </div>
      
    </main>
  );
}

export default LocationDetail;