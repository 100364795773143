import React, { useState } from 'react';

function CategNew() {
  const [categ, setCateg] = useState({
    title: '',
    iconname: '',
    links: '',
    color: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch('https://sus-map-backend.vercel.app/categ', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(categ),
    }).then(() => {
      setCateg({ title: '', iconname: '', links: '', color: '' });
    }).catch(error => {
      console.error('Error creating categ:', error);
    });
  };
  const canSubmit = categ.title && categ.iconname;
  return (
    <main>
      <center className='editMode'>
        <form onSubmit={handleSubmit}>
          <label>
            Category Title :<br />
            <input
              type="text"
              placeholder='Category title...(Required)'
              value={categ.title}
              onChange={(e) => setCateg({ ...categ, title: e.target.value })}
            /><br />
          </label>

          <br />

          <label>
            Icon name :<br />
            <select 
              id="images" 
              value={categ.iconname}
              onChange={(e) => setCateg({ ...categ, iconname: e.target.value })}
            >
              <option value="" disabled selected>Select your option(Required)</option>
              <option value="./images/default.png">Default</option>
              <option value="./images/solar.png">Solar</option>
              <option value="./images/composting.png">Composting</option>
              <option value="./images/e-waste.png">E-Waste</option>
              <option value="./images/ev-charging.png">EV-Charging</option>
              <option value="./images/geothermal.png">Geothermal</option>
              <option value="./images/leaf.png">Leaf</option>
              <option value="./images/leed.png">Leed</option>
              <option value="./images/ore-cart.png">Ore-Cart</option>
              <option value="./images/permeable-pavement.png">Permeable-Pavement</option>
              <option value="./images/plastic-recycling.png">Plastic Recycling</option>
              <option value="./images/recycling.png">Recycling</option>
              <option value="./images/battery.png">Battery</option>
              <option value="./images/light-bulb.png">Light Bulb</option>
              <option value="./images/tree.png">Tree</option>
              <option value="./images/power.png">Power</option>
              <option value="./images/water.png">Water</option>
              <option value="./images/pedestrian.png">Pedestrian</option>
              <option value="./images/wind.png">Wind</option>
            </select>
            <br />
          </label>

          <br />

          <label>
            Affiliated Links :<br />
            <textarea
              placeholder='Link...(Optional)'
              value={categ.links}
              onChange={(e) => setCateg({ ...categ, links: e.target.value })}
            /><br />
          </label>

          <br />

          <button type="submit" disabled={!canSubmit} >Create Category</button>
          <p style={{ color: 'red' }}>Need title and icon to make a Category</p>
        </form>
      </center>
    </main>
  );
}

export default CategNew;
