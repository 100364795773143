import React, { useState, useEffect, useRef} from 'react';
import { Loader } from "@googlemaps/js-api-loader";


function FeatureNew() {
  const [feature, setFeature] = useState({
    name: '',
    description: '',
    longitude: '',
    latitude: '',
    category: '',
    location: ''
  });


  const [categs, setCategs] = useState([]);
  const [optionsCat, setOptionsCat] = useState([]);
  const [locations, setLocations] = useState([]);
  const [optionsLoc, setOptionsLoc] = useState([]);

 

  const mapRef = useRef(null);
  const markerRef = useRef(null); // Use a ref to manage the marker instance
  useEffect(() => {
    fetch('https://sus-map-backend.vercel.app/categ')
      .then(response => response.json())
      .then(categs => {
        setCategs(categs);
        const updatedOptionsCat = categs.map(categ => ({
          id: categ._id, // Assuming the ID field is named '_id'
          title: categ.title
        }));
        setOptionsCat(updatedOptionsCat);
      })
      .catch(error => console.error('Error fetching categories:', error));
  }, []);
  useEffect(() => {
    fetch('https://sus-map-backend.vercel.app/location')
      .then(response => response.json())
      .then(locations => {
        setLocations(locations);
        const updatedOptionsLoc = locations.map(location => ({
          id: location._id, // Assuming the ID field is named '_id'
          name: location.name
        }));
        setOptionsLoc(updatedOptionsLoc);
      })
      .catch(error => console.error('Error fetching locations:', error));
  }, []);

  useEffect(() => {
    const loader = new Loader({
      apiKey : process.env.REACT_APP_GAPI,  
      version: "weekly",
    });

    loader.load().then(() => {
      const center = { lat: 39.750663664 , lng: -105.220165786 }; // Default center
      mapRef.current = new window.google.maps.Map(document.getElementById("map"), {
        center: center,
        zoom: 15,
        mapTypeId: window.google.maps.MapTypeId.SATELLITE,
      });

      mapRef.current.addListener("click", (e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        setFeature(f => ({ ...f, latitude: lat, longitude: lng }));

        if (markerRef.current) {
          markerRef.current.setPosition({ lat, lng });
        } else {
          markerRef.current = new window.google.maps.Marker({
            position: { lat, lng },
            map: mapRef.current,
            title: "New Feature Location"
          });
        }
      });
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!feature.name || !feature.longitude || !feature.latitude || !feature.category) {
      alert('Please fill out all required fields.');
      return;
    }
    
    fetch('https://sus-map-backend.vercel.app/feature', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(feature),
    })
    .then(() => {
      setFeature({ name: '', description: '',  longitude:'', latitude:'', category:'', location:'' });
    })
    .catch(error => {
      console.error('Error creating feature:', error);
    });
  };

  const canSubmit = feature.name && feature.longitude && feature.latitude && feature.category;

  return (
    <main>
      <div className='featureNew'>
        <div className="form-section">
          <center className='editMode'>
            <form onSubmit={handleSubmit}>
              <label>
                Feature Name :<br></br>
              </label>
              <input 
                type="text" 
                value={feature.name} 
                placeholder='Name...(Required)'
                onChange={(e) => setFeature({ ...feature, name: e.target.value })} 
              /><br></br>

              <br></br>

          
              <label>
                Description :<br></br>
              </label>
              <textarea 
                value={feature.description} 
                placeholder='Description...(Optional)'
                onChange={(e) => setFeature({ ...feature, description: e.target.value })} 
              /><br></br>
          
              <br></br>


              <label>
                Latitude :<br></br>
              </label>
              <input 
                type="number" 
                placeholder='Latitude...(Required)'
                value={feature.latitude} 
                onChange={(e) => setFeature({ ...feature, latitude: e.target.value })} 
              /><br></br>

              <br></br>


              <label>
                Longitude :<br></br>
              </label>
              <input 
                type="number" 
                placeholder='Longitude...(Required)'
                value={feature.longitude} 
                onChange={(e) => setFeature({ ...feature, longitude: e.target.value })} 
              /><br></br>

              <br></br>


              <label>Category:<br /></label>
              <select 
                value={feature.category} 
                onChange={(e) => setFeature({ ...feature, category: e.target.value })}>
                <option value="">Select a category(Required)</option>
                {optionsCat.map((option, index) => (
                  <option key={index} value={option.id}>{option.title}</option>
                ))}
              </select><br />

              {/* Select for Location */}
              <label>Location:<br /></label>
              <select 
                value={feature.location} 
                onChange={(e) => setFeature({ ...feature, location: e.target.value })}>
                <option value="">Select a location(Optional)</option>
                {optionsLoc.map((option, index) => (
                  <option key={index} value={option.id}>{option.name}</option>
                ))}
              </select><br />

              <button type="submit" disabled={!canSubmit}>Create Feature</button>
              <p style={{color:'red'}}>*Need name, latitude, longitude, and category to make a feature*</p>
            </form>
          </center>
        </div>
        <div id="map"></div>
      </div>
    </main>
  );
}

export default FeatureNew;