import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function CategList() {
  const [categs, setCategs] = useState([]);

  useEffect(() => {
    fetch('https://sus-map-backend.vercel.app/categ')
      .then((response) => response.json())
      .then((categs) => {
        const sortedCategs = categs.sort((a, b) => a.title.localeCompare(b.title));
        setCategs(sortedCategs);
      })
      .catch((error) => console.error('Error fetching categs:', error));
  }, []);

  return (
    <main>
      <div>
        <h1>Categories</h1>
        <nav className='list'>
          <ul>
            {categs.map((categ) => (
              <li key={categ._id}>
                <Link to={`/categ/${categ._id}`}>{categ.title}</Link>: Link: {categ?.links || 'No Link'}
              </li>
            ))}
          </ul>
          <Link name='feature' to='/categ/new'>
            <button name='feature'>Create New Category</button>
          </Link>
        </nav>
      </div>
      <p style={{ color: 'red' }}>*Refreshing will log you out*</p>
      <p style={{ color: 'red' }}>*If Create, Edit, Delete does not work, try relogin*</p>
    </main>
  );
}

export default CategList;
