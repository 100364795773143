import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Login from './Login'
import FeatureList from './FeatureList';
import FeatureNew from './FeatureNew';
import FeatureDetail from './FeatureDetail';
import FeatureListAll from './FeatureListAll';

import LocationList from './LocationList';
import LocationNew from './LocationNew';
import LocationDetail from './LocationDetail';
import CategList from './CategList';
import CategNew from './CategNew';
import CategDetail from './CategDetail';
import Home from './home';


import logo from './minesLogoWhite.png';


import './App.css';

function App() {
  const [user, setUser] = useState(()=>{
    const savedUser = localStorage.getItem('user');
    return savedUser ? JSON.parse(savedUser) : null;
  });
  
  useEffect(() => {
    // Function to handle tab/window closing
    const handleTabClose = (event) => {
      // Prevent default action to ensure the timeout can execute
      event.preventDefault();
  
      // Set a timeout to remove the user from localStorage
      const timeout = setTimeout(() => {
        localStorage.removeItem('user');
        console.log('User removed from localStorage because tab/browser was closed');
      }, 100); // 100 milliseconds should be enough to distinguish a close from a refresh
  
      // Listener to clear the timeout if the page is still active (like a refresh)
      window.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'visible') {
          clearTimeout(timeout);
        }
      });
  
      return event.returnValue;
    };
  
    // Add and remove event listener
    window.addEventListener('beforeunload', handleTabClose);
    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []);
  
  

  const handleLogin = (username, password) => {
    fetch(`https://sus-map-backend.vercel.app/login`, { // POST request to backend with username and password
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password }),
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        setUser({ username }); // Set user state if login is successful
      } else {
        alert('Login failed1: ' + data.message);// Show error message if login fails
      }
    });
  };
  const handleLogout =()=>{
    setUser(null);
    localStorage.removeItem('user');
  }

  return (
    <Router>
      <div className='content'>
        <header> 
          <img src={logo} alt="Colorado School of Mines Sustainability Map Data" className='logo'></img>
          
        </header>
        
          {user ? (
            <>
              <header>
                
                <center>
                <nav className='topNav'>
                  <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/" onClick={handleLogout}>Logout</Link></li>
                    <div className="dropdown">
                      <Link className="dropbtn">Feature</Link>
                        <div className="dropdown-content">
                          <Link to="/feature">Features List</Link>
                          <Link to="/feature/new">Create New Feature</Link>
                          <Link to="/feature/all">Features List All</Link>
                        </div>
                    </div>
                    <div className="dropdown">
                      <Link className="dropbtn">Location</Link>
                        <div className="dropdown-content">
                          <Link to="/location">Locations List</Link>
                          <Link to="/location/new">Create New Location</Link>
                        </div>
                    </div>
                    <div className="dropdown">
                      <Link className="dropbtn">Category</Link>
                        <div className="dropdown-content">
                          <Link to="/categ">Categories List</Link>
                          <Link to="/categ/new">Create New Category</Link>
    
                        </div>
                    </div>
                      
             
                  </ul>
                </nav>
                </center>
              </header>
            
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />

                <Route path="/feature" element={<FeatureList />} />
                <Route path="/feature/new" element={<FeatureNew />} />
                <Route path="/feature/:featureId" element={<FeatureDetail />} />
                <Route path="/feature/all" element={<FeatureListAll />} />

    
                <Route path="/location" element={<LocationList />} />
                <Route path="/location/new" element={<LocationNew />} />
                <Route path="/location/:locationId" element={<LocationDetail />} />

                <Route path="/categ" element={<CategList />} />
                <Route path="/categ/new" element={<CategNew />} />
                <Route path="/categ/:categId" element={<CategDetail />} />
              </Routes>
            
            </>
          ) : (
            
            <Login onLogin={handleLogin} />
            
          )}
        
        
      </div>
      
    </Router>
    
  );
}

export default App;
