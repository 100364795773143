import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import TroubleModal from './TroubleModal';

function Login({ onLogin }) {
  const [username, setUsername] = useState(''); //state to store user input
  const [password, setPassword] = useState(''); //state to stroe password input
  const [showModal, setShowModal] = useState(false); // State to control modal visibility

  const handleSubmit = (e) => {
    e.preventDefault();
    onLogin(username, password);
  };

  return (
    <main>
      <div className="login">
        <form onSubmit={handleSubmit}>
          <center>
            <label>
              <input name='user' type="text" placeholder='USERNAME' value={username} onChange={(e) => setUsername(e.target.value)} />
            </label>
            <label>
              <input name='password' type="password" placeholder='PASSWORD' value={password} onChange={(e) => setPassword(e.target.value)} />
            </label><br></br>
            <button type="submit">CONNECT</button>
            <button type="button" onClick={() => setShowModal(true)}>Trouble logging in?</button> {/* Adjusted to open modal */}
          </center>
        </form>
      </div>
      <TroubleModal isOpen={showModal} onClose={() => setShowModal(false)} /> {/* Include the modal component */}
    </main>
  );
}

export default Login;
