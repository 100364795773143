import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

function CategDetail() {
  const { categId } = useParams();
  const navigate = useNavigate();
  const [categ, setCateg] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editedCateg, setEditedCateg] = useState({});

  useEffect(() => {
    fetch(`https://sus-map-backend.vercel.app/categ/${categId}`)
      .then((response) => response.json())
      .then((categData) => {
        setCateg(categData);
        setEditedCateg(categData); // Initialize edit form with category data
      })
      .catch(error => console.error('Error fetching categ:', error));
  }, [categId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedCateg(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    fetch(`https://sus-map-backend.vercel.app/categ/${categId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(editedCateg),
    })
      .then(response => response.json())
      .then(data => {
        setCateg(data);
        setEditMode(false); // Exit edit mode
      })
      .catch(error => console.error('Error updating category:', error));
  };

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this category?')) {
      fetch(`https://sus-map-backend.vercel.app/categ/${categId}`, {
        method: 'DELETE'
      })
        .then(() => {
          navigate('/categ'); // Redirect to another page after deletion, for example, back to the categories list
        })
        .catch(error => console.error('Error deleting category:', error));
    }
  };

  if (!categ) return <div>Loading categ details...</div>;

  return (
    <main>
      <div className='detail'>
        {editMode ? (
          <div className='editMode'>
            <label>Title : </label>
            <input type="text" name="title" value={editedCateg.title} onChange={handleInputChange} /><br /><br />
            <label>Icon Name : </label>
            <select 
              id="images" 
              value={editedCateg.iconname}
              onChange={(e) => setEditedCateg({ ...editedCateg, iconname: e.target.value })}
            >
              <option value="" disabled>Select your option</option>
              <option value="./images/default.png">Default</option>
              <option value="./images/solar.png">Solar</option>
              <option value="./images/composting.png">Composting</option>
              <option value="./images/e-waste.png">E-Waste</option>
              <option value="./images/ev-charging.png">EV-Charging</option>
              <option value="./images/geothermal.png">Geothermal</option>
              <option value="./images/leaf.png">Leaf</option>
              <option value="./images/leed.png">Leed</option>
              <option value="./images/ore-cart.png">Ore-Cart</option>
              <option value="./images/permeable-pavement.png">Permeable-Pavement</option>
              <option value="./images/plastic-recycling.png">Plastic Recycling</option>
              <option value="./images/recycling.png">Recycling</option>
            </select><br /><br />
            <label>Affiliated Links : </label>
            <input type="text" name="links" value={editedCateg.links} onChange={handleInputChange} /><br /><br />
            <button onClick={handleSubmit}>Submit Changes</button>
            <button onClick={() => setEditMode(false)}>Cancel</button>
          </div>
        ) : (
          <div>
            <h1>{categ.title}</h1>
            <p>Icon name: {categ.iconname || 'No icon name available.'}</p>
            <p>Affiliated Links: {categ.links || 'No links available.'}</p>
            
            <button onClick={handleDelete}>Delete Category</button>
            <button onClick={() => setEditMode(true)}>Edit Category</button>
          </div>
        )}
      </div>
    </main>
  );
}

export default CategDetail;
