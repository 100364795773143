import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; 

function FeatureListAll() {
  const [featuresAll, setFeatures] = useState([]);

  useEffect(() => {
    fetch('https://sus-map-backend.vercel.app/feature/all')
      .then((response) => response.json())
      .then((featuresAll) => {
        setFeatures(featuresAll);
      })
      .catch((error) => console.error('Error fetching features:', error));
  }, []);
  
  return (
    <main>
      <div className='featureAll'>
        <h1>Features All</h1>
        <nav className='list'>
          <ul>
            {featuresAll.map((feature) => (
              
              <li key={feature._id}>
                 <Link to={`/feature/${feature._id}`}>{feature.name}</Link>: <b style={{color:'blue'}}>Location Info:</b> {feature?.location_data?.name || 'No Name'}, Address: {feature?.location_data?.address || 'No Address'} <b style={{color:'blue'}}>Category Info:</b> {feature?.category_data?.title || 'No Name'}, {feature?.category_data?.links || 'No Link'}
              </li>
            ))}
          </ul>
          <Link name='feature' to='/feature/new'>
            <button name='feature'>Create New Feature</button>
          </Link>
        </nav>
      </div>
      <p style={{ color: 'red' }}>*Refreshing will log you out*</p>
      <p style={{ color: 'red' }}>*If Create, Edit, Delete does not work, try relogin*</p>
    </main>
  );
}

export default FeatureListAll;