import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';


function FeatureDetail() {
  const { featureId } = useParams();
  const navigate = useNavigate();
  const [feature, setFeature] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [tags, setTags] = useState([]);
  const [editedFeature, setEditedFeature] = useState({});


  const [categs, setCategs] = useState([]);
  const [optionsCat, setOptionsCat] = useState([]);
  const [locations, setLocations] = useState([]);
  const [optionsLoc, setOptionsLoc] = useState([]);




  useEffect(() => {
    fetch(`https://sus-map-backend.vercel.app/feature/${featureId}`)
      .then(response => response.json())
      .then(featureData => {
        setFeature({
          ...featureData,
          category: featureData.category ? featureData.category : '',
          location: featureData.location ? featureData.location : ''
        });
        setEditedFeature({
          ...featureData,
          category: featureData.category ? featureData.category : '',
          location: featureData.location ? featureData.location : ''
        });
        setTags(featureData.tags || []);
      })
      .catch(error => console.error('Error fetching feature:', error));
  }, [featureId]);

  

  useEffect(() => {
    fetch('https://sus-map-backend.vercel.app/categ')
      .then(response => response.json())
      .then(categsData => {
        setCategs(categsData);
        setOptionsCat(categsData.map(c => ({ id: c._id, title: c.title })));
      })
      .catch(error => console.error('Error fetching categories:', error));
  }, []);


  useEffect(() => {
    fetch('https://sus-map-backend.vercel.app/location')
      .then(response => response.json())
      .then(locationsData => {
        setLocations(locationsData);
        setOptionsLoc(locationsData.map(location => ({ id: location._id, name: location.name })));
      })
      .catch(error => console.error('Error fetching locations:', error));
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedFeature(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    fetch(`https://sus-map-backend.vercel.app/feature/${featureId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(editedFeature),
    })
      .then(response => response.json())
      .then(data => {
        setFeature(data);
        setEditMode(false);
      })
      .catch(error => console.error('Error updating feature:', error));
  };


  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this feature?')) {
      fetch(`https://sus-map-backend.vercel.app/feature/${featureId}`, {
        method: 'DELETE'
      })
        .then(() => {
          navigate('/feature');
        })
        .catch(error => console.error('Error deleting feature:', error));
    }
  };

  if (!feature) return <div>Loading feature details...</div>;

  return (
    <main>
        <div className="detail">
            {editMode ? (
                <div className='editMode'>
                    <label>Name : </label>
                    <input type="text" name="name" value={editedFeature.name} onChange={handleInputChange} /><br /><br />
                    <label>Description: </label>
                    <textarea name="description" value={editedFeature.description} onChange={handleInputChange} /> <br /><br />
                    <label>Coordinate: </label>
                    <input type="text" name="latitude" value={editedFeature.latitude} onChange={handleInputChange} />
                    <input type="text" name="longitude" value={editedFeature.longitude} onChange={handleInputChange} /><br /><br />
                    <label>Category:</label>
                    <select 
                        name="category"
                        value={editedFeature.category} 
                        onChange={handleInputChange}>
                        <option value="">Select a category</option>
                        {optionsCat.map((option, index) => (
                            <option key={index} value={option.id}>{option.title}</option>
                        ))}
                    </select><br></br><br></br>
                    <label>Location:</label>
                    <select 
                        name="location"
                        value={editedFeature.location} 
                        onChange={handleInputChange}>
                        <option value="">Select a location</option>
                        {optionsLoc.map((option, index) => (
                            <option key={index} value={option.id}>{option.name}</option>
                        ))}
                    </select><br></br>
                    <button onClick={handleSubmit}>Submit Changes</button>
                    <button onClick={() => setEditMode(false)}>Cancel</button>
                </div>
            ) : (
                <div>
                    <h1>{feature.name}</h1>
                    <p>Description: {feature.description || 'No description available.'}</p>
                    <p>Coordinates: {feature.latitude + ', ' + feature.longitude || 'No coordinates available.'}</p>
                    <p>Category: {categs.find(c => c._id === feature.category)?.title || 'No category available.'}</p>
                    <p>Location: {locations.find(l => l._id === feature.location)?.name || 'No location available.'}</p>
                    
                    <button onClick={handleDelete}>Delete Feature</button>
                    <button onClick={() => setEditMode(true)}>Edit Feature</button>
                    
                </div>
            )}
        </div>
        <br></br>
    </main>
);
}

export default FeatureDetail;
